import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';

import $ from 'jquery';

import * as ROUTES from './constants/routes';

import NavBarLayout from './components/Navbar/NavBarLayout';
import NewMainPage from './components/pages/NewMain/NewMainPage';
import ProjectPage from './components/pages/Project/Project';
import BusinessPage from './components/pages/Business/Business';
import AboutPage from './components/pages/About/About';
import ContactPage from './components/pages/Contact/Contact';
import HololensTest from './components/pages/HololensTest';
import PrivacyPage from './components/Privacy/Privacy';

import { ChangeLanguage } from './_actions/i18nAction';

import './App.css';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css';
import NewBusinessPage from './components/pages/NewBusiness/NewBusinessPage';
import NewProjectPage from './components/pages/NewProject/NewProjectPage';

function App() {
    const [scrollActivePage, setScrollActivePage] = useState(0);

    const searchParams = new URLSearchParams(document.location.search);
    const dispatch = useDispatch();
    const { lang } = useSelector((state) => state.i18nReducer);

    useEffect(() => {
        let currentOrientation = function () {
            // Use screen.availHeight as screen height doesn't change when keyboard displays.
            if (window.screen.availHeight > window.screen.availWidth) {
                //	 세로모드일 때 실행할 이벤트
                $('html').addClass('portrait').removeClass('landscape');
            } else {
                //	가로모드일 때 실행할 이벤트
                $('html').addClass('landscape').removeClass('portrait');
            }
        };

        // Set orientation on initiliasation
        currentOrientation();
        // Reset orientation each time window is resized. Keyboard opening, or change in orientation triggers this.
        window.addEventListener('resize', currentOrientation, false);
    }, []);

    useEffect(() => {
        var enSite = "ymxsingapore.com";
        var isFirstAccess = window.sessionStorage.getItem("ymxEnSite");

        let paramsLang = searchParams.get('lang');
        if ((paramsLang !== null && lang !== paramsLang) || (isFirstAccess == null &&  window.location.href.includes(enSite))) {
            if(window.location.href.includes(enSite)){ window.sessionStorage.setItem("ymxEnSite", true);}
            dispatch(ChangeLanguage('en'));
        }
    }, []);
    useEffect(() => {
        let title = document.title;
        if(lang == "ko"){
            document.title = "YMX 홈페이지"
        } else if(lang == "en"){
            document.title = "YMX Homepage"
        } else if (lang == "jp"){
            document.title = "YMX ホームページ"
        }
    }, [lang]);
    return (
        <Router>
            <Routes>
                <Route element={<NavBarLayout activePage={scrollActivePage} />}>
                    <Route path={ROUTES.MAIN_PAGE} element={<NewMainPage />} />
                    <Route path={ROUTES.ABOUT_PAGE} element={<AboutPage />} />
                    <Route
                        path={ROUTES.BUSINESS_PAGE}
                        element={
                            <NewBusinessPage
                                activePage={(e) => {
                                    setScrollActivePage(e);
                                }}
                            />
                            // <BusinessPage
                            //     activePage={(e) => {
                            //         setScrollActivePage(e);
                            //     }}
                            // />
                        }
                    />
                    <Route
                        path={ROUTES.PROJECT_PAGE}
                        element={
                            <NewProjectPage
                                activePage={(e) => {
                                    setScrollActivePage(e);
                                }}
                            />
                            // <ProjectPage
                            //     activePage={(e) => {
                            //         setScrollActivePage(e);
                            //     }}
                            // />
                        }
                    />
                    <Route path={ROUTES.CONTACT_PAGE} element={<ContactPage />} />
                    {/* <Route path={ROUTES.RECRUIT_PAGE} element={<RecruitPage />} /> */}
                </Route>
                <Route path={ROUTES.PRIVACY_PAGE} element={<PrivacyPage />} />
                <Route path="/HololensTestPage" element={<HololensTest />} />
                <Route path="/*" element={<Navigate to={ROUTES.MAIN_PAGE} replace={true} />} />
            </Routes>
        </Router>
    );
}

export default App;
