import { Modal } from 'antd';
import React, { useState } from 'react';
import "antd/dist/antd.min.css"
import { CloseOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import './css/FrameYoutube.css'
import { useEffect } from 'react';

const youtubeUrl = (videoKey) => {
    return `https://www.youtube-nocookie.com/embed/${videoKey}?playsinline=1&enablejsapi=1&version=3&playerapiid=ytplayer&ecver=2&vq=hd720&rel=0`
}

export const FrameYoutubeBtn = (props) => {
    let { btnText, videoKey, videoWidth=1200, videoHeight=675} = props
    const [loading, setLoading] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);
    const [openPC, setOpenPC] = useState(false);
    const showModalPC = () => {
        setOpenPC(true);
        setOpenMobile(false)
        let iframe = document.querySelectorAll('iframe')[0]
        if(iframe !== undefined)iframe.setAttribute('src', `https://www.youtube-nocookie.com/embed/${videoKey}?playsinline=1&enablejsapi=1&version=3&playerapiid=ytplayer&ecver=2&vq=hd720&rel=0`);
    };
    const showModalMobile = () => {
        setOpenMobile(true);
        setOpenPC(false)
        let iframe = document.querySelectorAll('iframe')[0]
        if(iframe !== undefined)iframe.setAttribute('src', `https://www.youtube-nocookie.com/embed/${videoKey}?playsinline=1&enablejsapi=1&version=3&playerapiid=ytplayer&ecver=2&vq=hd720&rel=0`);
    };

    const modalStylePC = {
        backgroundColor: 'black',
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'flex-end',
        padding:0
    }
    const modalStyleMobile = {
        ...modalStylePC,
        alignItems:'center',
        height: '56vw'
    }

    const handleCancel = () => {
        setOpenPC(false);
        setOpenMobile(false)
        let iframe = document.querySelectorAll('iframe')[0]
        iframe.setAttribute('src', 'about:blank');
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}','*');

    };

    return (
    <>
        <div className='menuBtn PC' onClick={showModalPC}>{btnText}</div>
        <div className='menuBtn Mobile' onClick={showModalMobile}>{btnText}</div>
        <Modal
            open={openPC}
            title=""
            footer={null}
            bodyStyle={modalStylePC}
            maskClosable={false}
            closable={false}
            width={'95vw'}
            style={{maxWidth:'1200px'}}
            centered
        >
        <CloseOutlined style={{fontSize:'28px',color:'white'}} className='iframCloseBtn cursorPointer' onClick={handleCancel}/>
        <iframe src={`https://www.youtube-nocookie.com/embed/${videoKey}?playsinline=1&enablejsapi=1&version=3&playerapiid=ytplayer&ecver=2&vq=hd720&rel=0`} target="_self"
        title="video" width={'100%'} height={videoHeight} style={{border:'none'}}
        allowFullScreen="allowFullScreen"
        allow="fullscreen"
        />
        </Modal>
        <Modal
            open={openMobile}
            title=""
            footer={null}
            bodyStyle={modalStyleMobile}
            maskClosable={true}
            closable={false}
            width={'95vw'}
            centered
        >
        <CloseOutlined style={{fontSize:'28px',color:'white'}} className='iframCloseBtn cursorPointer' onClick={handleCancel}/>
        <iframe src={`https://www.youtube-nocookie.com/embed/${videoKey}?playsinline=1&enablejsapi=1&version=3&playerapiid=ytplayer&ecver=2&vq=hd720&rel=0`} target="_self"
        width={'100%'} height={'100%'}
        title="video" style={{border:'none'}}
        allowFullScreen="allowFullScreen"
        allow="fullscreen"
        />
        </Modal>
    </>
    );
};

export const FrameYoutubeModal = (props) => {
    let { videoKey, videoHeight=675} = props.modalData
    let { visible, setvisible, modalDataReset } = props
    const [openMobile, setOpenMobile] = useState(false);
    const [openPC, setOpenPC] = useState(false);
    
    useEffect(()=>{
        if(visible){
        let isMobile = window.innerWidth <=800 ? true : false;
        if(isMobile){
            setOpenPC(false)
            setOpenMobile(true)
        } else {
            setOpenPC(true)
            setOpenMobile(false)
        }
        let iframe = document.querySelectorAll('iframe')[0]
        if(iframe !== undefined)iframe.setAttribute('src', `https://www.youtube-nocookie.com/embed/${videoKey}?playsinline=1&enablejsapi=1&version=3&playerapiid=ytplayer&ecver=2&vq=hd720&rel=0`);
    }
    }, [visible])
    const modalStylePC = {
        backgroundColor: 'black',
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'flex-end',
        padding:0
    }
    const modalStyleMobile = {
        ...modalStylePC,
        alignItems:'center',
        height: '56vw'
    }

    const handleCancel = () => {
        setOpenPC(false);
        setOpenMobile(false)
        setvisible(false)
        modalDataReset()
        let iframe = document.querySelectorAll('iframe')[0]
        iframe.setAttribute('src', 'about:blank');
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}','*');
    };

    return (
    <>
        <Modal
            open={openPC}
            title=""
            footer={null}
            bodyStyle={modalStylePC}
            maskClosable={false}
            closable={false}
            width={'95vw'}
            style={{maxWidth:'1200px'}}
            centered
        >
        <CloseOutlined style={{fontSize:'28px',color:'white'}} className='iframCloseBtn cursorPointer' onClick={handleCancel}/>
        <iframe src={`https://www.youtube-nocookie.com/embed/${videoKey}?playsinline=1&enablejsapi=1&version=3&playerapiid=ytplayer&ecver=2&vq=hd720&rel=0`} target="_self"
         title="video" width={'100%'} height={videoHeight} style={{border:'none'}}
         allowFullScreen="allowFullScreen"
        allow="fullscreen"
         />
        </Modal>
        <Modal
            open={openMobile}
            title=""
            footer={null}
            bodyStyle={modalStyleMobile}
            maskClosable={true}
            closable={false}
            width={'95vw'}
            centered
        >
        <CloseOutlined style={{fontSize:'28px',color:'white'}} className='iframCloseBtn cursorPointer' onClick={handleCancel}/>
        <iframe src={`https://www.youtube-nocookie.com/embed/${videoKey}?playsinline=1&enablejsapi=1&version=3&playerapiid=ytplayer&ecver=2&vq=hd720&rel=0`} target="_self"
        width={'100%'} height={'100%'}
        title="video" style={{border:'none'}}
        allowFullScreen="allowFullScreen"
        allow="fullscreen"
        />
        </Modal>
    </>
    );
};