import { CHANGE_LANG } from '../_actions/types'
import {
    defaultLang,
    supportedLangs
  } from "../i18n/i18n";

const initialState = {
    lang: defaultLang,
    supportedLangs: { ...supportedLangs },
}
export default function i18nReducer (state = initialState, action) {
    switch (action.type) {
        case CHANGE_LANG:
            return { ...state, lang : action.payload }
        default:{
            return state;
        }
    }
}

