import React, { useEffect } from 'react';

const Map = (props) => {
  // <!-- 3. 실행 스크립트 -->
  const executeScript = () => {
    // console.log("executeScript");
    const scriptTag = document.createElement('script');
    let mapWidth =
      window.innerWidth > 1200 ? 800 : window.innerWidth > 950 ? 600 : window.innerWidth * 0.8;
    let mapHeight =
      window.innerWidth > 1200 ? 600 : window.innerWidth > 950 ? 500 : window.innerWidth * 0.6;
    const inlineScript = document.createTextNode(`new daum.roughmap.Lander({
    	"timestamp" : "1674621824783",
    	"key" : "2di8d",
      "mapWidth" : ${mapWidth},
      "mapHeight" :  ${mapHeight},
    }).render();`);
    scriptTag.appendChild(inlineScript);
    document.body.appendChild(scriptTag);
  };

  // <!-- 2. 설치 스크립트 * 지도 퍼가기 서비스를 2개 이상 넣을 경우, 설치 스크립트는 하나만 삽입합니다. -->
  // document.write 문제가 발생해서 해당 파일을 직접 가져온다음 수정했음
  const InstallScript = () => {
    (function () {
      // console.log("installScript");
      var c = window.location.protocol === 'https:' ? 'https:' : 'http:';
      var a = '6af7869e';

      if (window.daum && window.daum.roughmap && window.daum.roughmap.cdn) {
        // console.log("=====================================")
        // console.log(window.daum)
        // console.log(window.daum.roughmap)
        // console.log(window.daum.roughmap.cdn)
        // console.log("=====================================")
        return;
      }
      window.daum = window.daum || {};
      window.daum.roughmap = {
        cdn: a,
        URL_KEY_DATA_LOAD_PRE: c + '//t1.daumcdn.net/roughmap/',
        url_protocal: c,
      };
      var b = c + '//t1.daumcdn.net/kakaomapweb/place/jscss/roughmap/' + a + '/roughmapLander.js';

      // document.write -> doumnet.body.append로 수정
      const scriptTag = document.createElement('script');
      scriptTag.src = b;
      // console.log(scriptTag);
      document.body.append(scriptTag);
      // console.log("document.body.append");
      scriptTag.onload = () => {
        executeScript();
      };
    })();
  };

  useEffect(() => {
    InstallScript();
    if (document.getElementById('daumRoughmapContainer1674621824783').offsetHeight === 0) {
      // console.log(document.getElementById("daumRoughmapContainer1674621824783").offsetHeight)
      executeScript();
    }
  }, []);

  return (
    <>
      <div
        id="daumRoughmapContainer1674621824783"
        className="root_daum_roughmap root_daum_roughmap_landing"
      ></div>
    </>
  );
};

export default Map;
