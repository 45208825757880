import React, { useEffect, useState } from 'react';
import './css/Contact.css';
import { isEmail } from '../../Util/commonFunc';
import { useSelector } from 'react-redux';
import { Checkbox, Input } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { error } from 'jquery';

const { TextArea } = Input;

const SpinContainer = styled.div`
  /* Absolute Center Spinner */
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0,
      rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0,
      rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0,
      rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0,
      rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0,
      rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0,
      rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  }

  /* Animation */

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const ContactMail = () => {
  const [loading, setLoading] = useState(false);
  const [mailModalvisible, setMailModalvisible] = useState(false);
  const [mailModalText, setMailModalText] = useState('');

  const { lang } = useSelector((state) => state.i18nReducer);
  const { r } = useSelector((state) => state.languageReducer);
  const t = r[lang];
  const [visible, setvisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [type, settype] = useState('');
  const [contactdata, setContactdata] = useState({
    category: 'product',
    industry: '',
    company: '',
    department: '',
    username: '',
    position: '',
    email: '',
    contactNumber: '',
    title: '',
    contents: '',
    agree: false,
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const onCheckFunc = (e) => {
    e.preventDefault();
    console.log(e.currentTarget.name + ' : ' + e.target.value);
    setContactdata({ ...contactdata, [e.currentTarget.name]: e.target.value });
  };
  const onCheckCategory = (value) => {
    setContactdata({ ...contactdata, category: value });
  };
  const onCheckIndustry = (value) => {
    setContactdata({ ...contactdata, industry: value });
  };
  const onChangeInput = (e) => {
    setContactdata({ ...contactdata, [e.currentTarget.name]: e.target.value });
  };
  const setAgree = (event) => {
    if (!contactdata.agree) {
      setvisible(true);
      settype('privacyRule');
    } else {
      setContactdata({ ...contactdata, agree: !!event.currentTarget.value });
    }
  };
  var categoryList = [
    { label: t['contact_bodyText_1_1'], value: 'product' },
    { label: t['contact_bodyText_1_2'], value: 'others' },
  ];
  var industryList = [
    { label: t['contact_bodyText_3_1'], value: 'ITdev' },
    { label: t['contact_bodyText_3_2'], value: 'service' },
    { label: t['contact_bodyText_3_3'], value: 'edu' },
    { label: t['contact_bodyText_3_4'], value: 'medi' },
    { label: t['contact_bodyText_3_5'], value: 'public' },
    { label: t['contact_bodyText_3_6'], value: 'sale' },
    { label: t['contact_bodyText_3_7'], value: 'manufacturing' },
    { label: t['contact_bodyText_3_8'], value: 'others' },
  ];
  const [isCheck, setIsCheck] = useState(true);
  useEffect(() => {
    if (
      contactdata.username !== '' &&
      contactdata.email !== '' &&
      contactdata.contactNumber !== '' &&
      !(contactdata.category === 'product' && contactdata.company === '') &&
      contactdata.contents !== '' &&
      contactdata.agree !== false &&
      contactdata.industry !== ''
    ) {
      setIsCheck(false);
    } else {
      setIsCheck(true);
    }
  }, [contactdata]);
  const sendContactFunc = () => {
    if (!isCheck) {
      Mailto(contactdata);
    } else {
      console.log('disabled');
    }
  };
  const Mailto = async (mailInfo) => {
    let {
      category, //문의 유형
      username, // 고객명
      email, // 이메일
      contactNumber, // 연락처
      company, // 기업/기관
      department, // 부서
      position, // 직급
      industry, // 산업군별
      contents, // 문의 내용
      agree,
    } = mailInfo;

    if (!agree) {
      return false;
    }

    const sendMailData = {
      category: category === 'product' ? '제품 문의' : '기타',
      name: username,
      email,
      phone: contactNumber,
      companyName: company,
      department,
      position,
      industry:
        industry === 'ITdev'
          ? 'IT개발'
          : industry === 'service'
          ? '서비스'
          : industry === 'edu'
          ? '교육'
          : industry === 'medi'
          ? '의료'
          : industry === 'public'
          ? '공공기관'
          : industry === 'sale'
          ? '도소매'
          : industry === 'manufacturing'
          ? '제조업'
          : '기타',
      content: contents,
    };

    setLoading(true);

    await axios
      .post(`https://data.mxspace.co.kr/common/contact/ymx`, sendMailData)
      .then((res) => {
        setLoading(false);
        if (Number(res.data.resultCode) === Number(200)) {
          setMailModalvisible(true);
          setMailModalText('문의가 등록 되었습니다.');
        } else {
          setMailModalvisible(true);
          setMailModalText('전송이 실패되었습니다.<br/>네트워크 상황을 확인 후 다시 시도해주세요.');
        }
      })
      .catch((error) => {
        setLoading(false);
        setMailModalvisible(true);
        setMailModalText('전송이 실패되었습니다.<br/>네트워크 상황을 확인 후 다시 시도해주세요.');
      });
  };

  const getCategoryLabel = (value) => {
    let temp = categoryList.filter((c) => c.value === value);
    return temp[0].label;
  };

  const getIndustryLabel = (value) => {
    let temp = industryList.filter((c) => c.value === value);
    return temp[0].label;
  };

  const openPrivacyRule = () => {
    setContactdata({ ...contactdata, agree: !contactdata.agree });
  };
  return (
    <div className="ContactWholeWrapper" style={{ marginTop: 80 }}>
      {loading && (
        <SpinContainer>
          <div className="loading">Loading</div>
        </SpinContainer>
      )}
      <SendMailModal
        visible={mailModalvisible}
        mailModalText={mailModalText}
        modalClose={() => {
          setMailModalvisible(false);
          setMailModalText('');
        }}
      />
      {/* <div className='emptydiv'></div> */}
      <div className="ContactWrapper">
        <div className="ContactHeader">
          <div className="ContactHeaderText">{t['contact_mainTitle_1']}</div>
          <div className="ContactHeaderTitle">Your Meta eXperience, YMX</div>
          <div className={`ContactHeaderSubTitle ${isFinished ? 'pcHiddenMHidden' : ''}`}>
            ‘*’ {t['contact_required_1']}
          </div>
        </div>
        <div className={`ContactContainer`}>
          <div className={`ContactInputWrapper ${isFinished ? 'pcHiddenMNone' : ''}`}>
            <div className="ContactInputHeader ContactInputWrapperL">
              {t['contact_bodyTitle_1']}
              <span className="starMark">*</span>
            </div>
            <div className="ContactInputContainer ContactCheckboxList">
              {categoryList.map((item) => {
                return (
                  <div
                    className={`ContactCheckboxItem cursorPointer
                                ${item.value === contactdata.category ? 'selected' : 'nonSelected'}
                                ${item.value === 'empty' ? 'empty' : ''}`}
                    key={item.label}
                    onClick={() => onCheckCategory(item.value)}
                  >
                    <Checkbox
                      key={item.label}
                      name="category"
                      onChange={(e) => onCheckFunc(e)}
                      checked={item.value === contactdata.category}
                      value={item.value}
                      style={{ display: 'none' }}
                    ></Checkbox>
                    {item.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={`ContactInputWrapper ContactInputWrapperL ContactInputWrapperS ContactInputWrapperB ContactInputWrapperG ${
              isFinished ? 'pcHiddenMNone' : ''
            }`}
          >
            <div className="ContactInputHeader">{t['contact_bodyTitle_2']}</div>
            <div className="ContactInputContainer ContactInputList">
              <div className="ContactInputitems ">
                <div className="firstItems">
                  {t['contact_bodyText_2_1']}
                  <span className="starMark">*</span>
                </div>
                <Input
                  autoComplete="off"
                  name="username"
                  placeholder={t['contact_bodyText_2_1_2']}
                  value={contactdata.username}
                  onChange={(e) => onChangeInput(e)}
                />
              </div>
              <div className="ContactInputitems">
                <div>
                  {t['contact_bodyText_2_2']}
                  <span className="starMark">*</span>
                </div>
                <Input
                  autoComplete="off"
                  name="email"
                  placeholder={t['contact_bodyText_2_2_2']}
                  status={contactdata.email !== '' && !isEmail(contactdata.email) ? 'error' : ''}
                  onChange={(e) => onChangeInput(e)}
                />
              </div>
              <div className="ContactInputitems">
                <div>
                  {t['contact_bodyText_2_3']}
                  <span className="starMark">*</span>
                </div>
                <Input
                  autoComplete="off"
                  name="contactNumber"
                  placeholder={t['contact_bodyText_2_3']}
                  onChange={(e) => onChangeInput(e)}
                />
              </div>
              <div className="ContactInputitems">
                <div>
                  {t['contact_bodyText_2_4']}
                  <span className="starMark">{contactdata.category === 'others' ? '' : '*'}</span>
                </div>
                <Input
                  autoComplete="off"
                  name="company"
                  placeholder={t['contact_bodyText_2_4_2']}
                  onChange={(e) => onChangeInput(e)}
                />
              </div>
              <div className="ContactInputitems ContactInputitemsPC">
                <div>{t['contact_bodyText_2_5']}</div>
                <Input
                  autoComplete="off"
                  name="department"
                  placeholder={t['contact_bodyText_2_5']}
                  onChange={(e) => onChangeInput(e)}
                />
              </div>
              <div className="ContactInputitems ContactInputitemsPC">
                <div>{t['contact_bodyText_2_6']}</div>
                <Input
                  autoComplete="off"
                  name="position"
                  placeholder={t['contact_bodyText_2_6']}
                  onChange={(e) => onChangeInput(e)}
                />
              </div>
            </div>
          </div>
          <div className="ContactInputWrapper ContactInputWrapperPC ContactInputWrapperL ContactInputWrapperG">
            <div
              className={`ContactInputHeader ContactInputWrapperL ${
                isFinished ? 'pcNoneMNone' : ''
              }`}
            >
              {t['contact_bodyTitle_3']}
              <span className="starMark">*</span>
            </div>
            <div
              className={`ContactInputHeader ContactFinished ${!isFinished ? 'pcNoneMNone' : ''}`}
            >
              {t['contact_complete_1']}
            </div>
            <div
              className={`ContactInputContainer ContactCheckboxList2 ${
                isFinished ? 'pcNoneMNone' : ''
              }`}
            >
              {industryList.map((item) => {
                return (
                  <div
                    className={`ContactCheckboxItem cursorPointer
                                ${
                                  item.value === contactdata.industry ? 'selected' : 'nonSelected'
                                }`}
                    key={item.label}
                    onClick={() => onCheckIndustry(item.value)}
                  >
                    <Checkbox
                      key={item.label}
                      name="industry"
                      onChange={(e) => onCheckFunc(e)}
                      checked={item.value === contactdata.industry}
                      value={item.value}
                      style={{ display: 'none' }}
                    />
                    {item.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="ContactInputWrapper ContactInputWrapperC"
            style={isFinished ? { visibility: 'hidden' } : {}}
          >
            <div className="ContactInputHeader">
              {t['contact_bodyTitle_4']}
              <span className="starMark">*</span>
            </div>
            <TextArea
              name="contents"
              placeholder={t['contact_bodyTitle_4_1']}
              onChange={(e) => onChangeInput(e)}
            />
          </div>
          <div
            className="ContactInputWrapper ContactPrivacyWrapper"
            style={isFinished ? { visibility: 'hidden' } : {}}
          >
            <div style={{ position: 'relative' }}>
              <Checkbox
                name="agree"
                checked={contactdata.agree}
                onClick={openPrivacyRule}
              ></Checkbox>
              <span className="checkboxLabel" onClick={openPrivacyRule}>
                <span className="privacyRule">{t['contact_privacy_1']}</span>
                {t['contact_privacy_2']}
              </span>
            </div>
          </div>
          <div
            className={`ContactInputWrapper ContactBtnWrapper ${
              isFinished ? 'pcHiddenMHidden' : ''
            }`}
          >
            <div
              type="primary"
              onClick={sendContactFunc}
              className={`${isCheck ? 'disabled' : ''}`}
            >
              {t['contact_complete_2']}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='emptydiv'></div> */}
    </div>
  );
};

export default ContactMail;

const SendMailModal = (props) => {
  let { visible, modalClose, mailModalText } = props;

  const StyledSendModal = styled.div`
    .sendMailModalMask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
    }
    .sendMailModalWrap {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      outline: 0;
      z-index: 1000;
    }
    .sendMailModal {
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      font-size: 16px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum';
      pointer-events: none;
      position: relative;
      top: 100px;
      max-width: calc(100vw - 32px);
      margin: 0 auto;
      padding: 0 0 24px;
      width: 314px;
    }
    .sendMailModalContent {
      position: relative;
      background-color: #fff;
      background-clip: padding-box;
      border: 0;
      border-radius: 10px 10px 11px 11px;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
      pointer-events: auto;
      height: 165px;
      padding-top: 49px;
    }

    .sendMailModalBtn {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #00a9ff;
      border-radius: 0 0 10px 10px;
      height: 45px;
      font-size: 16px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: -0.5px;
      color: #ffffff;
      cursor: pointer;
    }
  `;

  return (
    <>
      {visible && (
        <StyledSendModal>
          <div className="sendMailModalMask"></div>
          <div className="sendMailModalWrap">
            <div className="sendMailModal">
              <div className="sendMailModalContent">
                <p dangerouslySetInnerHTML={{ __html: mailModalText }} />
                <div className="sendMailModalBtn" onClick={modalClose}>
                  확인
                </div>
              </div>
            </div>
          </div>
        </StyledSendModal>
      )}
    </>
  );
};
