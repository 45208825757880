import React from 'react';

import { useSelector } from 'react-redux';

import CheckMobile from '../../Util/MobileCheck';

import { StyledTechnology } from './style';

import mainTechnology from './images/technology/main_technology.png';
import technology1 from './images/technology/technology1.png';
import technology2 from './images/technology/technology2.png';

const TechnologySection = () => {
    const { lang } = useSelector((state) => state.i18nReducer);
    const { r } = useSelector((state) => state.languageReducer);

    const t = r[lang];

    const isMobile = CheckMobile();

    return (
        <StyledTechnology>
            <div className="technology_1">
                <div>
                    <div style={{ zIndex: 1 }}>
                        <p className="technologyTitle">Metaverse technology</p>
                        <p className="technologyText">INDUSTRIAL METAVERSE</p>
                    </div>
                    <div className="technologyImg">
                        <img src={mainTechnology} alt="" />
                    </div>
                </div>
            </div>
            <div className="technology_2">
                <div>
                    <img src={technology1} alt="" />
                    <p className="technologyTitle">{t['newMainPage_technology1_Title']}</p>
                    <p
                        className="technologyText"
                        dangerouslySetInnerHTML={{
                            __html: isMobile
                                ? t['newMainPage_mb_technology1_Text']
                                : t['newMainPage_pc_technology1_Text'],
                        }}
                    />
                </div>
                <div>
                    <img src={technology2} alt="" />
                    <p className="technologyTitle">{t['newMainPage_technology2_Title']}</p>
                    <p
                        className="technologyText"
                        dangerouslySetInnerHTML={{
                            __html: isMobile
                                ? t['newMainPage_mb_technology2_Text']
                                : t['newMainPage_pc_technology2_Text'],
                        }}
                    />
                </div>
            </div>
        </StyledTechnology>
    );
};

export default TechnologySection;
