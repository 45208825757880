

function HololensTest() {

  return (
    <div style={{width:"100vw", height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', fontSize:"50px", border:"2px solid orange"}}>홀로렌즈 테스트</div>
  );
}

export default HololensTest;
