import { combineReducers } from 'redux';
import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from "redux-persist";

import i18nReducer from './i18nReducer'
import languageReducer from './languageReducer'

const persistConfig = {
    key: "lang",
    storage,
    whitelist: ["i18nReducer"],
    //혹시 유저 정보에 민감 정보가 추가되는 경우 reducer를 분리하거나 session에 저장되는 부분에 암호화 필요
    // blacklist: ["languageReducer"]
};

export const rootReducer = combineReducers({
  i18nReducer,
  languageReducer
  });
export default persistReducer(persistConfig, rootReducer);