import { compose } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import persistReducer from "../_reducers";
import ReduxThunk from "redux-thunk";
import { persistStore } from "redux-persist";


const store = configureStore({
        reducer: persistReducer,
        middleware: [ReduxThunk],
    });
export const persistor = persistStore(store)

export default store;